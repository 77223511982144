<template>
  <section class="tarif">

    <div class="container-fluid">
      <div class="section__title center">
        <h2>
          <router-link to="">
            {{ $t('tariff.title') }}
            <i class="icon-arrow-right"></i>
          </router-link>
        </h2>

      </div>


      <testSwiper
          v-for="(tariffItem, index) in opti" :key="index"
          :tariff-data="tariffItem"
          @sendTicket="sendTicket"
      />

      <p class="note">
        {{ $t('tarifBlock.conditions') }}
      </p>


    </div>

  </section>


</template>

<script>

import axios from "axios";
import testSwiper from "@/components/testSwiper";


export default {
  name: "tariffss",
  data: function () {
    return {
      activeTariff: true,
      optiBtn: false,
      optiProBtn: true,
      active: false,
      name: '',
      phone: '',
      message: '',
      active2: false,
      input1: '',
      input2: '',
      mes: '',
      optiPro: [],
      opti: [],
      tarifIndex: 0
    }
  },
  async created() {
    const tariff = await axios.get(
        `https://admin.optikom.uz/api/v1/web/tariffs`,
        {}
    );
    this.opti = tariff.data.data.list;
    console.log(this.opti)

  },
  components: {testSwiper},
  methods: {

    sendTicket(tariff) {
      this.active = true
      console.log(tariff)
    }
  }
}
</script>

<style lang="scss">
@import "src/scss/vars";
.note {
  margin-top: 2.5rem;
  font-size: 0.7rem;
}
.btn-toggle {
  margin-bottom: 50px;
}

.section__title {
  h2 {
    margin-bottom: 60px;
  }
}


.tarif {
  padding: 100px 0;


}

</style>