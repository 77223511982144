<template>
  <footer class="footer">
    <div class="footermain">
      <div class="footermain__block">
        <div v-scroll-reveal="{ delay: 200 }" class="footermain__block-item">
          <h3 class="section-title">{{ $t('footer.servicesForHome.title') }}</h3>
          <ul>
            <li>
              <router-link :to="{ name: 'homePage' }">
                {{ $t('footer.servicesForHome.internet') }}
              </router-link>
            </li>

            <li :class="{ active: this.$route.name === 'coverages' }">
              <router-link :to="{ name: 'coverages' }">
                {{ $t('footer.servicesForHome.coverage') }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'homePage' }">
                {{ $t('footer.servicesForHome.tv') }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'homePage' }">
                {{ $t('footer.servicesForHome.phone') }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'homePage' }">
                {{ $t('footer.servicesForHome.video') }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'homePage' }">
                {{ $t('footer.servicesForHome.smartHome') }}
              </router-link>
            </li>
          </ul>
        </div>
        <div v-scroll-reveal="{ delay: 250 }" class="footermain__block-item">
          <h3 class="section-title">
            {{ $t('footer.forOffice.title') }}
          </h3>
          <ul>
            <li>
              <router-link :to="{ name: 'corporates' }">
                {{ $t('footer.forOffice.internet') }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'corporates' }">
                {{ $t('footer.forOffice.phone') }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'corporates' }">
                {{ $t('footer.forOffice.video') }}
              </router-link>
            </li>
          </ul>
        </div>
        <div v-scroll-reveal="{ delay: 300 }" class="footermain__block-item">
          <h3 class="section-title">
            {{ $t('footer.aboutCompany.title') }}
          </h3>
          <ul>
            <li :class="{ active: this.$route.name === 'about' }">
              <router-link :to="{ name: 'about' }">{{ $t('footer.aboutCompany.aboutUs') }}</router-link>
            </li>
            <li :class="{ active: this.$route.name === 'contact' }">
              <router-link :to="{ name: 'contact' }">{{ $t('footer.aboutCompany.contacts') }}</router-link>
            </li>
            <li
                :class="{
                active:
                  this.$route.name === 'news' ||
                  this.$route.name === 'newsDetail',
              }"
            >
              <router-link :to="{ name: 'news' }">
                {{ $t('footer.aboutCompany.saleAndNews') }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'documents' }">
                {{ $t('footer.aboutCompany.publicOffer') }}
              </router-link>
            </li>
            <li>
              <a href="https://gis.uz/" target="_blank">
                {{ $t('footer.aboutCompany.uzkom') }}
              </a>
            </li>
          </ul>
        </div>
        <div v-scroll-reveal="{ delay: 350 }" class="footermain__block-item">
          <h3 class="section-title">
            {{ $t('footer.servicesForHome.payments') }}
          </h3>

          <div class="payments">
            <img src="../../assets/payments/click.png" alt="click"/>
            <img src="../../assets/payments/humans.png" alt="humans"/>
            <img src="../../assets/payments/payme.png" alt="payme"/>
            <img src="../../assets/payments/paynet.png" alt="paynet"/>
            <img src="../../assets/payments/uzum.png" alt="upay"/>
            <img
                src="../../assets/payments/kapitalbank.png"
                alt="kapitalbank"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="footermin">
      <div v-scroll-reveal="{ delay: 400 }" class="footermin__copy">
        <p>© 2021 - {{ currentDate.getFullYear() }} <strong>Optikom</strong></p>
      </div>

      <div v-scroll-reveal="{ delay: 450 }" class="footermin__logo">
        <img src="../../assets/optikom.svg"/>
      </div>

      <div v-scroll-reveal="{ delay: 500 }" class="footermin__social">
        <div class="footermin__social-block">
          <a href="https://t.me/optikomuz" target="_blank">
            <i class="icon-telegram"></i>
          </a>
          <a href="https://www.instagram.com/optikom.uz/" target="_blank">
            <i class="icon-instagram"></i>
          </a>
          <a href="https://www.facebook.com/optikom.uz" target="_blank">
            <i class="icon-facebook"></i>
          </a>
        </div>

        <div class="footermin__social-copy">
          <p>
            © 2021 - {{ currentDate.getFullYear() }} <strong>Optikom</strong>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footermain",
  data: function () {
    return {
      logoUrl: "img/optikom.svg",
      currentDate: new Date(),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.footer {
  .footermain {
    &__block {
      display: flex;
      flex-wrap: wrap;

      &-item {
        width: 25%;
        padding: 30px;
        border: 1px solid $borderColor;
        @media screen and (max-width: 1200px) {
          &:first-child {
            padding-left: 80px !important;
          }

          &:last-child {
            padding-right: 80px !important;
          }
        }

        @media screen and (max-width: 1000px) {
          width: 50%;
          padding: 20px 0 20px 50px !important;
          &:nth-child(3) {
            border-top: none;
            border-right: 1px solid $borderColor !important;
          }
          &:nth-child(4) {
            border-top: none;
            border-left: none;
          }
          &:first-child {
            padding-left: 50px !important;
          }

          &:last-child {
            padding-right: 50px !important;
          }
        }

        @media screen and (max-width: 600px) {
          padding: 25px 0 25px 25px !important;
          &:first-child {
            padding-left: 25px !important;
          }

          &:last-child {
            padding-right: 25px !important;
          }
        }

        @media screen and (max-width: 500px) {
          width: 100% !important;
          padding: 25px 25px !important;
        }

        &:nth-child(2) {
          border-left: none;
          border-right: none;
        }

        &:nth-child(3),
        &:nth-child(4) {
          border-right: none;
        }

        &:first-child {
          padding-left: 100px;
        }

        &:last-child {
          padding-right: 100px;
        }

        .section-title {
          font-style: normal;
          font-weight: 700;
          font-size: 1.2rem;
          line-height: 130%;
          letter-spacing: -0.025em;
        }

        ul {
          display: flex;
          flex-direction: column;
          margin-top: 15px;

          li {
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 22px;
            margin-bottom: 10px;

            &.active {
              a {
                color: $colorBlue;
              }
            }

            a {
              color: $colorBlack;
              display: inline-flex;
              @extend .transition;

              &:hover {
                color: $colorBlue;
              }

              &:active {
                @extend .scaleMin;
              }
            }
          }
        }
      }

      .payments {
        display: flex;
        //flex-direction: column;
        flex-wrap: wrap;
        gap: 20px 6%;
        align-items: center;
        justify-content: center;

        img {
          width: 47%;
          max-width: 150px;

          &:last-child {
            width: 100%;
          }
        }
      }
    }
  }

  .footermin {
    display: flex;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      &__logo {
        width: 100% !important;
      }
      &__social {
        width: 100% !important;
      }
    }

    &__copy {
      padding: 20px;
      width: 25%;
      padding-left: 100px;
      display: flex;
      border: 1px solid $borderColor;
      border-top: none;
      align-items: center;
      @media screen and (max-width: 1200px) {
        padding-left: 80px !important;
      }
      @media screen and (max-width: 1000px) {
        display: none;
      }

      p {
        margin: 0;
      }
    }

    &__logo {
      padding: 20px;
      display: flex;
      width: 50%;
      justify-content: center;
      border-bottom: 1px solid $borderColor;
      align-items: center;

      img {
        max-width: 200px
      }

      @media screen and (max-width: 1000px) {
        width: 50%;
        justify-content: flex-start;
        padding-left: 50px;
      }

      @media screen and (max-width: 600px) {
        padding: 25px 0 25px 25px !important;
      }

      @media screen and (max-width: 600px) {
        width: 100% !important;
        justify-content: center;
        padding: 15px !important;
      }
    }

    &__social {
      padding: 20px;
      width: 25% !important;
      padding-right: 100px;
      display: flex;
      border: 1px solid $borderColor;
      border-top: none;
      align-items: center;

      &-copy {
        display: none;
      }

      @media screen and (max-width: 1200px) {
        padding-right: 80px !important;
      }
      @media screen and (max-width: 1000px) {
        padding-right: 0 !important;
        width: 50% !important;
        flex-direction: column;
        padding-left: 50px;
        &-block {
          width: 100% !important;
        }

        @media screen and (max-width: 600px) {
          padding: 25px 0 25px 25px !important;
        }

        @media screen and (max-width: 600px) {
          width: 100% !important;
          justify-content: center;
          padding: 15px !important;
          &-block {
            width: 100% !important;
            justify-content: center !important;
            display: flex !important;
          }
          &-copy {
            justify-content: center;
            width: 100%;

            p {
              margin-bottom: 0 !important;
            }
          }
        }
        &-copy {
          display: inline-flex !important;
          width: 100%;

          p {
            margin-bottom: 0 !important;
          }
        }
      }

      a {
        color: $colorBlack;
        font-size: 1.5rem;
        @extend .transition;

        &:hover {
          color: $colorBlue;
        }
      }
    }
  }
}
</style>
