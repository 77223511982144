<template>
  <section class="service">
    <div class="container-fluid">
      <div class="section__title center">
        <h2>
          {{ $t('service.additional') }}
        </h2>
      </div>
      <div class="service__block">
        <article v-for="(serviceItem, index) in services" :key="index" class="service__item">

          <div class="service__item-icon">
            <img :alt="serviceItem.name " :src="serviceItem.icon">
          </div>

          <div class="service__item-title">
            <h3>
              {{ serviceItem.name }}
            </h3>
            <p>
              {{ serviceItem.text }}
            </p>
          </div>

          <div class="service__item-cash">
            <h4>
              {{ serviceItem.price }}
            </h4>
          </div>

        </article>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "serviceItems",
  data: function () {
    return {
      services: []
    }
  },
  async mounted() {
   const lang = localStorage.getItem('lang')

    const DATA_FROM_API = await axios.get(`https://admin.optikom.uz/api/v1/web/getAdditionalService?lang=${lang}`);
    this.services = DATA_FROM_API.data.data.list;


  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.service {
  padding: 100px 0;

  .section__title {
    margin-bottom: 60px;
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    gap: 30px 2%;
  }

  &__item {
    background: #fff;
    padding: 30px;
    width: 18.4%;
    border-radius: 20px;
    align-items: center;

    @extend .transition;

    &:hover {
      transform: translateY(-20px);
      box-shadow: 0 40px 40px rgba(0, 0, 0, 0.1);
    }

    &-icon {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;

      img {
        max-width: 4rem;
        color: $colorBlue;
      }
    }

    &-title {
      text-align: center;
      margin-bottom: 15px;

      h3 {
        font-size: 1.2rem;
        font-weight: 600;
      }

      p {
        font-size: 0.9rem;
        margin-bottom: 0;
      }
    }

    &-cash {
      h4 {
        text-align: center;
        margin: 0;
        font-weight: 600;
      }

      small {
        font-size: 0.9rem;
      }
    }

  }

  @media screen and (max-width: 1600px) {
    .service__item {
      width: 23.5% !important;
    }

  }
  @media screen and (max-width: 1000px) {
    .service__block {
      gap: 15px 2%;
    }
    .service__item {
      width: 32% !important;
    }

  }
  @media screen and (max-width: 700px) {
    .service__item {
      width: 49% !important;
    }

  }
  @media screen and (max-width: 700px) {
    .service__item {
      width: 100% !important;
    }

  }
}

</style>