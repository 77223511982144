<template>
  <section class="team">
    <div class="container-fluid">
      <div class="section__title center">
        <h2>
          {{ $t('aboutUs.team') }}
        </h2>

      </div>

      <div class="team__block">
        <div v-for="(teamItem, index) in team" :key="index" class="team__item" v-scroll-reveal="{ delay: index*100 }">
          <div class="team__item-poster">
            <img :alt=teamItem.name :src=teamItem.image>
          </div>
          <div class="team__item-name">
            <h3>
              {{ teamItem.name }}
              <small>
                {{ teamItem.department }}
              </small>
            </h3>

          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "about-team",
  data: function () {
    return {
      team:[],

    }
  },
  async created() {
    const lang = localStorage.getItem('lang')
    const DATA_FROM_API = await axios.get(`https://admin.optikom.uz/api/v1/web/getEmployeeList?lang=${lang}`);
    this.team = DATA_FROM_API.data.data;


  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.team {
  padding: 0 0 100px 0;

  .team__block {
    display: flex;
    gap: 0 2%;
    //margin-top: 200px;
    align-items: stretch;
    flex-wrap: wrap;

    .team__item {
      width: 23.5%;
      background: $colorWhite;
      border-radius: 30px;
      margin-top: 15%;


      &-name {
        padding: 20px 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        h3 {
          display: inline-flex;
          flex-direction: column;
          font-weight: 700;
          font-size: 1.4rem;
          line-height: 120%;
          text-align: center;
          letter-spacing: -0.025em;
          color: $colorBlack;

          small {
            font-weight: 400 !important;
            font-size: 1.2rem;
            line-height: 120%;
            text-align: center;
            letter-spacing: -0.025em;
            color: $colorBlack;
          }
        }
      }

      &-poster {
        height: 300px;
        position: relative;

        img {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }
    }
  }


  @media screen and (max-width: 1700px) {

    .team__block {
      //margin-top: 150px;
      .team__item {
        margin-top: 10%;
      }
    }

  }
  @media screen and (max-width: 1600px) {

    .team__block {
      //margin-top: 150px;
      .team__item {
        margin-top: 12%;
        &-poster {
          height: 250px;
        }
      }
    }

  }



  @media screen and (max-width: 1400px) {

    .team__block {
      //margin-top: 100px;
      .team__item {
        &-poster {
          height: 250px;
        }
      }
    }

  }
  @media screen and (max-width: 1300px) {

    .team__block {
      //margin-top: 150px;
      .team__item {
        margin-top: 7%;
        &-poster {
          height: 220px;
        }
      }
    }

  }

  @media screen and (max-width: 1100px) {

    .team__block {
      //margin-top: 150px;
      .team__item {
        margin-top: 12%;
        width: 32%;
        &-poster {
          height: 250px;
        }
      }
    }

  }

  @media screen and (max-width: 850px) {

    .team__block {
      //margin-top: 200px;
      .team__item {
        margin-top: 30%;
        width: 49%;
        &-poster {
          height: 250px;
        }
      }
    }

  }


  @media screen and (max-width: 700px) {

    .team__block {
      //margin-top: 150px;
      .team__item {
        margin-top: 25%;
      }
    }

  }
  @media screen and (max-width: 550px) {

    .team__block {
      //margin-top: 150px;
      .team__item {
        margin-top: 10%;
      }
    }

  }

  @media screen and (max-width: 500px) {

    .team__block {
      //margin-top: 150px;

      .team__item {
        width: 100%;
        margin-top: 70%;
      }
    }

  }
  @media screen and (max-width: 450px) {

    .team__block {
      //margin-top: 150px;

      .team__item {
        margin-top: 60%;
      }
    }

  }

  @media screen and (max-width: 400px) {

    .team__block {
      //margin-top: 150px;

      .team__item {
        margin-top: 50%;
      }
    }

  }
  @media screen and (max-width: 350px) {

    .team__block {
      //margin-top: 150px;

      .team__item {
        margin-top: 30%;
      }
    }

  }
}

</style>